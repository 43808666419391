<template>
  <q-dialog v-model="dialogCommissionSchedule" @show="setValue()" persistent>
    <q-card>
      <q-card-section>
        <q-form @submit="editSchedule">
          <div class="col-3">
            <q-input
              outlined
              v-model="params.title"
              type="text"
              dense
              label="Título do Agendamento"
              lazy-rules
              :rules="[isRequired]"
            />
          </div>
          <div class="q-gutter-md row">
            <div class="col">
              <q-input
                label="Data de ativação"
                dense
                mask="####-##-##"
                outlined
                v-model="date"
                lazy-rules
                :rules="[isRequired]"
              >
                <template v-slot:prepend>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-date v-model="date" mask="YYYY-MM-DD HH:mm:ss">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="col">
              <q-input
                label="Horário de ativação"
                dense
                outlined
                v-model="time"
                lazy-rules
                mask="##:##"
                :rules="[isRequired]"
              >
                <template v-slot:prepend>
                  <q-icon name="access_time" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-time v-model="time" mask="HH:mm">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-time>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
          </div>
          <div class="q-gutter-md row">
            <div class="col">
              <q-select
                outlined
                use-input
                v-model="destinationSelected"
                label="Grupo de Destino"
                dense
                clearable
                clear-icon="mdi-close"
                lazy-rules
                :options="destinyList"
                @filter="filterDestiny"
                :rules="[isRequired]"
                @input="treatDestinyGroupSelection"
                option-label="name"
                option-value="id"
              ></q-select>
            </div>
            <div class="col">
              <q-select
                outlined
                use-input
                v-model="selectedGroup"
                label="Grupo de Origem"
                dense
                clearable
                clear-icon="mdi-close"
                :options="groupFiltered"
                @filter="filterGroups"
                @input="addGroupChannelsToSchedule"
                option-label="name"
                lazy-rules
              />
            </div>
          </div>
          <q-card-section class="scroll">
            <q-toolbar class="bg-grey-3 text-dark" style="height: 85px">
              <q-toolbar-title class="col-6 text-body1"> Canais selecionados: </q-toolbar-title>
              <q-select
                outlined
                class="col-6"
                use-input
                label="Adicionar mais canais"
                dense
                clearable
                clear-icon="mdi-close"
                lazy-rules
                bg-color="white"
                option-label="site_name"
                option-value="site_id"
                v-model="selectedChannel"
                :options="filteredChannels"
                @filter="filterSites"
                @input="addSelectedChannel"
                :rules="[shouldHaveSelectedChannel]"
              >
              </q-select>
            </q-toolbar>
            <q-list bordered class="scroll" style="height: 200px">
              <q-item v-for="channel in selectedChannels" :key="channel.site_id" clickable v-ripple>
                <q-item-section>
                  <q-item-label>{{ channel.site_name }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-btn flat dense round icon="delete" color="negative" @click="removeChannel(channel.site_id)" />
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
          <div class="q-dialog__buttons" align="right">
            <q-btn flat color="negative" @click="ActionSetDialogCommissionSchedule(false)">Cancelar</q-btn>
            <q-btn flat color="primary" label="Salvar" type="submit" />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import CampaignMixin from '@m/CampaignMixin';
import ComissionScheduleMixin from '@m/ComissionScheduleMixin';
import ComissionSchedulePageMixin from '../../reusables/ComissionSchedulePageMixin.js';

export default {
  mixins: [CampaignMixin, ComissionScheduleMixin, ComissionSchedulePageMixin],
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    async editSchedule() {
      this.params.site_id_list = this.selectedChannels.map((channel) => channel.site_id);
      this.params.comission_group_id = this.destinationSelected.id;
      this.params.date = this.date + ' ' + this.time;

      try {
        this.onLoading(true);
        const { data } = await this.editCommissionSchedule(this.params);
        this.successNotify(data.message);
        this.closeDialog();
        this.updateSchedulesList();
        this.destinyList = this.destinyBackup;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    setValue() {
      this.params = { ...this.commissionScheduleSelect };
      this.selectedChannels = this.channelList.filter((val) =>
        this.commissionScheduleSelect.site_id_list.includes(val.site_id)
      );
      this.destinationSelected = this.destinyList.filter((destiny) => destiny.id == this.params.comission_group_id)[0];

      this.groupList = this.destinyBackup.filter((group) => group.id != this.params.comission_group_id);

      var splitedDate = this.commissionScheduleSelect.date.split(' ');
      this.date = splitedDate[0];
      this.time = splitedDate[1];
    },

    closeDialog() {
      this.clearData();
      this.ActionSetDialogCommissionSchedule(false);
    }
  }
};
</script>
