<template>
  <div>
    <ListHistoric :campaign_id="campaign_id" @view-changes="changeInfo" />
    <!-- <ViewHistoricChanges -->
    <!--   v-if="selectedHistoricLine" -->
    <!--   :is-open-p="openChangeView" -->
    <!--   :historic-line="selectedHistoricLine" -->
    <!--   @hide="openChangeView = false" -->
    <!-- /> -->
  </div>
</template>

<script>
import ListHistoric from './ListHistoric.vue';
// import ViewHistoricChanges from './ViewHistoricChanges.vue';
export default {
  components: {
    ListHistoric
    // ViewHistoricChanges
  },

  props: {
    campaign_id: {
      type: [String, Number],
      require: true
    }
  },

  data() {
    return {
      openChangeView: false,
      selectedHistoricLine: null,
      childKey: 0
    };
  },

  methods: {
    changeInfo(historic) {
      this.openChangeView = true;
      this.selectedHistoricLine = historic;
      this.childKey++;
    }
  }
};
</script>

<style></style>
