<template>
  <q-table
    flat
    bordered
    :grid="grid"
    :data="channels"
    row-key="id"
    :columns="columns"
    :pagination="pagination"
    :visible-columns="visibleColumns"
  >
    <template v-slot:body-cell-status="props">
      <q-td :props="props">
        <q-chip v-if="props.value === 0" text-color="white" color="warning">Pendente </q-chip>
        <q-chip v-if="props.value === 1" text-color="white" color="primary">Aprovado </q-chip>
        <q-chip v-if="props.value === 2" text-color="white" color="accent">Pausado </q-chip>
        <q-chip v-if="props.value === 3" text-color="white" color="red">Reprovado </q-chip>
        <q-chip v-if="props.value === 4" text-color="white" color="negative">Removido </q-chip>
      </q-td>
    </template>

    <template v-slot:body-cell-created_at="props">
      <q-td :props="props">
        {{ $moment(props.row.created_at).format('L') }}
      </q-td>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <q-btn
          unelevated
          round
          flat
          icon="mdi-thumb-up"
          color="primary"
          @click="editChannel(props.row.campaign_site_id, 1)"
          :disable="props.row.campaign_site_status == 1"
        >
          <!-- @click="ActionSetDialogCampaign({ value: props.row })" -->
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Aprovar </q-tooltip>
        </q-btn>

        <q-btn
          unelevated
          round
          flat
          icon="mdi-pause"
          color="primary"
          @click="editChannel(props.row.campaign_site_id, 2)"
          :disable="props.row.campaign_site_status == 2"
        >
          <!-- @click="ActionSetDialogCampaign({ value: props.row })" -->
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Pausar </q-tooltip>
        </q-btn>

        <q-btn
          unelevated
          round
          flat
          icon="mdi-thumb-down"
          color="primary"
          @click="editChannel(props.row.campaign_site_id, 3)"
          :disable="props.row.campaign_site_status == 3"
        >
          <!-- @click="ActionSetDialogCampaign({ value: props.row })" -->
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Reprovar </q-tooltip>
        </q-btn>

        <q-btn
          unelevated
          round
          flat
          icon="mdi-delete-empty"
          color="primary"
          @click="editChannel(props.row.campaign_site_id, 4)"
          :disable="props.row.campaign_site_status == 4"
        >
          <!-- @click="ActionSetDialogCampaign({ value: props.row })" -->
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Remover </q-tooltip>
        </q-btn>
      </q-td>
    </template>
  </q-table>
</template>
<script>
import CampaignMixin from '../../mixins/CampaignMixin';

export default {
  name: 'ListCampaign',
  mixins: [CampaignMixin],
  props: {
    id: {
      type: [String, Number],
      require: true
    },

    status: []
  },

  data() {
    return {
      grid: false,
      visibleColumns: null,
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },
  created() {
    this.getChannelsById(this.id);
    this.visibleColumns = this.columns.map((column) => column.name);
  },

  methods: {
    async editChannel(site_id, turnToStatus) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.put('/redirect', {
          app: 'ADVERTISER',
          path: '/api/edit/campaign/site/' + site_id,
          params: `{
              "data":{
                "status": ${turnToStatus}
              }
            }`
        });
        if (status === 200) {
          this.successNotify(data.message);
          const itemUpdate = this.channels.find((item) => item.campaign_site_id == site_id);
          itemUpdate.campaign_site_status = turnToStatus;
          Object.assign(this.channels, itemUpdate);

          this.channelsStatusCount.pending = this.channelsAll.filter((item) => item.campaign_site_status == 0).length;
          this.channelsStatusCount.approved = this.channelsAll.filter((item) => item.campaign_site_status == 1).length;
          this.channelsStatusCount.paused = this.channelsAll.filter((item) => item.campaign_site_status == 2).length;
          this.channelsStatusCount.reproved = this.channelsAll.filter((item) => item.campaign_site_status == 3).length;
          this.channelsStatusCount.deleted = this.channelsAll.filter((item) => item.campaign_site_status == 4).length;

          if (this.status && this.status.length > 0)
            this.ActionSetChannels(
              this.channels.filter((item) => this.status.includes(item.campaign_site_status.toString()))
            );
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>
