<template>
  <div>
    <q-table
      flat
      bordered
      :grid="grid"
      row-key="id"
      :data="historicData"
      :columns="columns"
      :visible-columns="visibleColumns"
      hide-bottom
    >
      <template v-slot:body-cell-user_id="props">
        <q-td :props="props"> {{ props.row.user_id }}</q-td>
      </template>

      <template v-slot:body-cell-date="props">
        <q-td :props="props"> {{ parseDate(props.row.created_at) }}</q-td>
      </template>
      <!---->
      <!-- <template v-slot:body-cell-event="props"> -->
      <!--   <q-td :props="props"> {{ $t(props.row.change_type) }}</q-td> -->
      <!-- </template> -->

      <template v-slot:body-cell-previous-status="props">
        <q-td :props="props"> {{ $t(props.row.diff.status.old) }}</q-td>
      </template>

      <template v-slot:body-cell-later-status="props">
        <q-td :props="props"> {{ $t(props.row.diff.status.new) }}</q-td>
      </template>
      <!-- <template v-slot:body-cell-action="props"> -->
      <!--   <q-td :props="props"> -->
      <!--     <q-btn unelevated round flat icon="mdi-view-list" color="primary" @click="openChangesView(props.row)"> -->
      <!--       <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> -->
      <!--         {{ $t('view_changes') }} -->
      <!--       </q-tooltip> -->
      <!--     </q-btn> -->
      <!--   </q-td> -->
      <!-- </template> -->
    </q-table>
  </div>
</template>

<script>
import AdvertiserService from '../../../services/AdvertiserService.js';

// const mockedData = [
//   {
//     id: 4,
//     campaign_id: 3,
//     user_id: 1,
//     change_type: 'create', old_data: null,
//     new_data: '{"status":"ok","message":"Sucess"}',
//     created_at: '2023-10-18 12:38:55',
//     updated_at: '2023-10-18 12:38:55',
//     diff: '{"status":"ok","message":"Sucess"}'
//   },
//   {
//     id: 5,
//     campaign_id: 3,
//     user_id: 1,
//     change_type: 'update',
//     old_data: '{"status":"ok","message":"Sucess"}',
//     new_data: '{"status":"ok","message":"New Success"}',
//     created_at: '2023-10-18 12:40:09',
//     updated_at: '2023-10-18 12:40:09',
//     diff: '{"message":"Sucess"}'
//   },
//   {
//     id: 6,
//     campaign_id: 3,
//     user_id: 1,
//     change_type: 'update',
//     old_data: '{"status":"ok","message":"New Success"}',
//     new_data: '{"status":"error","message":"Missing data"}',
//     created_at: '2023-10-18 12:41:08',
//     updated_at: '2023-10-18 12:41:08',
//     diff: '{"status":"ok","message":"New Success"}'
//   },
//   {
//     id: 7,
//     campaign_id: 3,
//     user_id: 1,
//     change_type: 'delete',
//     old_data: '{"status":"error","message":"Missing data"}',
//     new_data: null,
//     created_at: '2023-10-18 12:41:25',
//     updated_at: '2023-10-18 12:41:25',
//     diff: '{"status":"error","message":"Missing data"}'
//   },
//   {
//     id: 8,
//     campaign_id: 3,
//     user_id: 1,
//     change_type: 'delete',
//     old_data: '{"status":"error","message":"Missing data"}',
//     new_data: null,
//     created_at: '2023-10-19 03:21:49',
//     updated_at: '2023-10-19 03:21:49',
//     diff: '{"status":"error","message":"Missing data"}'
//   },
//   {
//     id: 9,
//     campaign_id: 3,
//     user_id: 1,
//     change_type: 'delete',
//     old_data: '{"status":"error","message":"Missing data"}',
//     new_data: null,
//     created_at: '2023-10-19 04:14:21',
//     updated_at: '2023-10-19 04:14:21',
//     diff: '{"status":"error","message":"Missing data"}'
//   }
// ];

export default {
  mixins: [AdvertiserService],
  props: {
    campaign_id: {
      type: [String, Number],
      require: true
    }
  },
  data() {
    return {
      grid: false,
      historicData: [],
      rowBackgroundColors: {
        create: 'bg-green-3',
        update: 'bg-light-blue-3',
        delete: 'bg-red-3'
      },
      visibleColumns: null,
      columns: [
        {
          name: 'user_id',
          align: 'left',
          label: this.$t('user') + ' ID',
          field: 'user_id',
          sortable: true
        },

        {
          name: 'user',
          align: 'left',
          label: 'Usuário',
          field: 'user',
          sortable: true
        },
        {
          name: 'date',
          align: 'left',
          label: this.$t('modification_date'),
          field: 'created_at'
        },

        {
          name: 'previous-status',
          align: 'center',
          label: 'Status anterior'
        },
        {
          name: 'later-status',
          align: 'center',
          label: 'Status posterior'
        }

        // {
        //   name: 'event',
        //   align: 'left',
        //   label: 'Evento',
        //   field: 'change_type'
        // },

        // {
        //   name: 'action',
        //   align: 'center',
        //   label: this.$t('view_changes')
        // }
      ]
    };
  },

  methods: {
    async getHistoric() {
      try {
        const { data, status } = await this.getCampaignHistoric(this.campaign_id);
        if (status == 200) {
          this.historicData = data.data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    openChangesView(historicLine) {
      this.$emit('view-changes', historicLine);
    },

    parseDate(date) {
      return this.$moment(date, 'YYYY-MM-DD hh:mm:ss').format('L');
    }
  },

  created() {
    this.visibleColumns = this.columns.map((col) => col.name);
    this.getHistoric();
  }
};
</script>

<style></style>
