<template>
  <div>
    <q-table
      flat
      bordered
      :grid="grid"
      row-key="id"
      :data="commissionSchedulesList"
      :columns="columns"
      :pagination="pagination"
      :visible-columns="visibleColumns"
    >
      <template v-slot:body-cell-status="props">
        <q-td :props="props">
          {{ $t(props.row.status) }}
        </q-td>
      </template>
      <template v-slot:body-cell-action="props">
        <q-td :props="props">
          <q-btn
            unelevated
            round
            flat
            icon="mdi-pencil-outline"
            color="primary"
            @click="openScheduleEditForm(props.row)"
          >
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
              {{ $t('edit') }}
            </q-tooltip>
          </q-btn>

          <q-btn unelevated round flat icon="mdi-content-copy" color="secondary" @click="copySchedule(props.row.id)">
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
              {{ $t('duplicate') }}
            </q-tooltip>
          </q-btn>

          <q-btn unelevated round flat icon="mdi-cancel" color="negative" @click="cancelSchedule(props.row.id)">
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
              {{ $t('cancel') }}
            </q-tooltip>
          </q-btn>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import ComissionScheduleMixin from '../../mixins/ComissionScheduleMixin';
import AdvertiserService from '../../services/AdvertiserService';

export default {
  mixins: [CampaignMixin, ComissionScheduleMixin, AdvertiserService],
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      grid: false,
      visibleColumns: null,
      editDialogVisible: false,
      columns: [
        {
          name: 'N°',
          label: 'N°',
          align: 'left',
          field: 'id',
          sortable: true
        },
        {
          name: 'title',
          align: 'left',
          label: 'Título do Agendamento',
          field: 'title',
          sortable: true
        },
        {
          name: 'Data do Agendamento',
          align: 'left',
          label: 'Data do Agendamento',
          field: 'date',
          sortable: true
        },
        {
          name: 'status',
          align: 'left',
          label: 'Status',
          field: 'status'
        },
        {
          name: 'action',
          align: 'center',
          label: 'Ações'
        }
      ],
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },

  methods: {
    async getComissionSchedules() {
      this.onLoading(true);
      try {
        const { data, status } = await this.listComissionSchedule(this.id);
        if (status === 200) {
          this.ActionSetCommissionSchedulesList(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async openScheduleEditForm(schedule) {
      if (schedule.status == 'Canceled' || schedule.status == 'Done') {
        this.errorNotify(`commission_schedule_${schedule.status}_edit_error`);
        return;
      }

      try {
        this.onLoading(true);
        const { data } = await this.getComissionScheduleByID(schedule.id);

        this.ActionSetDialogCommissionSchedule({ value: data });
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async cancelSchedule(scheduleID) {
      try {
        this.onLoading(true);
        const { data } = await this.cancelScheduleByID(scheduleID);
        this.successNotify(data.message);
        this.getComissionSchedules();
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async copySchedule(scheduleID) {
      try {
        this.onLoading(true);
        const { data } = await this.getComissionScheduleByID(scheduleID);

        data.title += ' (Cópia) ';
        this.ActionSetDialogCreateCommissionSchedule({ value: data });
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },

  created() {
    this.getComissionSchedules();
    this.visibleColumns = this.columns.map((col) => col.name);
  }
};
</script>
