<template>
  <div>
    <q-card-section :key="uiUpdateKey" class="row q-col-gutter-md">
      <div class="col-12">
        <q-input
          outlined
          v-model="invoice.registration_code"
          :disabled="disabled"
          :readonly="disabled"
          type="text"
          unmasked-value
          mask="##.###.###/####-##"
          label="CNPJ*"
          lazy-rules
          @input="retrieveCnpjDataForCampaignBilling"
          :rules="[isRequired]"
        />
      </div>
      <div class="col-6">
        <q-input
          outlined
          v-model="invoice.type"
          :disabled="disabled"
          :readonly="disabled || cnpjAutoFillReadonly"
          type="text"
          label="Pais*"
          lazy-rules
          :rules="[isRequired]"
        />
      </div>
      <div class="col-6">
        <q-input
          outlined
          v-model="invoice.inscricao_estadual"
          :disabled="disabled"
          :readonly="disabled"
          type="text"
          label="Inscrição Estadual"
        />
      </div>
      <div class="col-12">
        <q-input
          outlined
          v-model="invoice.company_name"
          :disabled="disabled"
          :readonly="disabled || cnpjAutoFillReadonly"
          type="text"
          label="Razão Social*"
          lazy-rules
          :rules="[isRequired]"
        />
      </div>
      <div class="col-12">
        <q-input
          outlined
          v-model="invoice.address"
          :disabled="disabled"
          :readonly="disabled || cnpjAutoFillReadonly"
          type="text"
          label="Endereço*"
          lazy-rules
          :rules="[isRequired]"
        />
      </div>
      <div class="col-2">
        <q-input
          outlined
          v-model="invoice.state"
          :disabled="disabled"
          :readonly="disabled || cnpjAutoFillReadonly"
          type="text"
          label="UF*"
          lazy-rules
          :rules="[isRequired]"
        />
      </div>
      <div class="col-5">
        <q-input
          outlined
          v-model="invoice.city"
          :disabled="disabled"
          :readonly="disabled || cnpjAutoFillReadonly"
          type="text"
          label="Cidade*"
          lazy-rules
          :rules="[isRequired]"
        />
      </div>
      <div class="col-5">
        <q-input
          outlined
          v-model="invoice.phone"
          :disabled="disabled"
          :readonly="disabled"
          type="text"
          label="Telefone*"
          lazy-rules
          :rules="[isRequired]"
        />
      </div>
      <div class="col-6">
        <q-input
          outlined
          v-model="invoice.inscricao_municipal"
          :disabled="disabled"
          :readonly="disabled"
          type="text"
          label="Inscrição Municipal"
        />
      </div>
      <div class="col-6">
        <q-input
          outlined
          v-model="invoice.postal_code"
          :disabled="disabled"
          :readonly="disabled || cnpjAutoFillReadonly"
          type="text"
          label="CEP*"
          lazy-rules
          :rules="[isRequired]"
        />
      </div>
      <div class="col-6">
        <q-input
          outlined
          v-model="invoice.deadline"
          :disabled="disabled"
          :readonly="disabled"
          type="number"
          label="Prazo Faturamento*"
          lazy-rules
          :rules="[isRequired]"
        />
      </div>
      <div class="col-6">
        <q-input
          v-if="disabled"
          outlined
          v-model="invoiceEmail"
          :disabled="disabled"
          :readonly="disabled"
          type="email"
          label="E-mail*"
          lazy-rules
          :rules="[isRequired, isEmail]"
        />
        <q-input
          v-else
          outlined
          v-model="invoice.email"
          type="email"
          label="E-mail*"
          lazy-rules
          :rules="[isRequired, isEmail]"
        />
      </div>
    </q-card-section>

    <slot name="footer"></slot>
  </div>
</template>

<script>
import UserService from '../../services/UserService.js';

export default {
  name: 'FormCampaignBilling',
  mixins: [UserService],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    invoice: {
      type: [Object, Array],
      default: () => ({
        type: null,
        registration_code: null,
        company_name: null,
        inscricao_estadual: null,
        address: null,
        state: null,
        city: null,
        phone: null,
        inscricao_municipal: null,
        postal_code: null,
        deadline: null,
        email: null
      })
    }
  },
  data() {
    return {
      invoiceEmail: null,
      uiUpdateKey: 0,
      cnpjAutoFillReadonly: false
    };
  },
  methods: {
    deleteClicked1() {
      console.log('aprtou');
    },

    formatEmail() {
      this.invoiceEmail = JSON.parse(this.invoice.email)[0];
    },

    async retrieveCnpjDataForCampaignBilling(value) {
      const isValidCnpj = this.isCNPJ(value);
      if (isValidCnpj && typeof isValidCnpj == 'boolean') {
        try {
          this.onLoading(true);
          const { data } = await this.getCnpjData(value);
          this.invoice.company_name = data.razao_social;
          this.invoice.address = data.endereco;
          this.invoice.postal_code = data.cep;
          this.invoice.city = data.cidade;
          this.invoice.state = data.estado;
          this.invoice.type = data.pais;
          this.cnpjAutoFillReadonly = true;
          this.uiUpdateKey++;
        } catch (error) {
          if (error.response && error.response.status === 500)
            this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
          else this.errorNotify(error);
        } finally {
          this.onLoading(false);
        }
      } else {
        this.cnpjAutoFillReadonly = false;
      }
    }
  },
  watch: {
    invoice() {
      if (this.invoice.email && this.disabled) {
        this.formatEmail();
      }
    }
  }
};
</script>

<style></style>
