<template>
  <q-card flat bordered>
    <q-toolbar class="bg-primary text-white">
      <q-input
        outlined
        v-model="comissionGroup.name"
        disabled="true"
        type="text"
        dense
        bg-color="white"
        class="full-width"
      />
      <q-btn
        unelevated
        round
        flat
        icon="mdi-checkbox-marked-circle-outline"
        color="white"
        @click="changeComissionGroupName(comissionGroup.id, comissionGroup.name)"
      >
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Alterar </q-tooltip>
      </q-btn>
      <q-btn
        unelevated
        round
        flat
        icon="mdi-delete"
        color="white"
        @click="showDialogDelGroupCommission(comissionGroup.id, comissionGroup.name)"
      >
        <!-- @click="deleteComissionGroup(comissionGroup.id)" -->
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Deletar </q-tooltip>
      </q-btn>
    </q-toolbar>
    <q-card-section>
      <q-select
        label="Escolha um canal..."
        use-input
        input-debounce="200"
        @filter="filterSites"
        dense
        multiple
        hide-selected
        v-model="comissionGroup.sites"
        :options="sitesFiltered.filter((item) => !comissionGroup.sites.find((el) => el.site_id == item.site_id))"
        option-value="id"
        option-label="site_name"
        outlined
        @input="(value) => createSiteInComissionGroup(value.at(-1), comissionGroup)"
      />
    </q-card-section>
    <q-card-section class="scroll">
      <q-toolbar class="bg-grey-3 text-dark">
        <q-toolbar-title class="text-body1"> Canais selecionados: </q-toolbar-title>
        <q-input
          placeholder="Buscar..."
          type="text"
          outlined
          bg-color="white"
          dense
          clearable
          clear-icon="close"
          v-model="filterSite"
          @input="filterSitesSelected"
          @clear="comissionGroup.sites = comissionGroupFull.sites"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-toolbar>

      <q-list bordered class="scroll" style="height: 200px">
        <q-item
          v-for="(commissionGroupSites, commissionGroupIndex) in comissionGroup.sites"
          :key="commissionGroupIndex"
        >
          <q-item-section>
            <q-item-label>{{ commissionGroupSites.site_name }} </q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-btn
              icon="mdi-close"
              flat
              dense
              round
              @click="showDialogDelSite(commissionGroupIndex, comissionGroup.sites, comissionGroup)"
            />
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
  </q-card>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';

export default {
  name: 'ComissionGroupEach',
  mixins: [CampaignMixin],
  props: {
    id: {
      type: [String, Number],
      require: true
    },
    comissionGroup: {},
    allSites: []
  },

  data() {
    return {
      sitesFiltered: [],
      comissionGroupFull: {},
      filterSite: null,
      firstTime: true
    };
  },

  watch: {
    comissionGroup: {
      handler() {
        if (this.firstTime) {
          this.comissionGroupFull = { sites: this.comissionGroup.sites };
          this.firstTime = false;
        }
      },
      deep: true
    }
  },

  methods: {
    filterSitesSelected() {
      const needle = this.filterSite.toLocaleLowerCase();
      if (!needle) this.comissionGroup.sites = this.comissionGroupFull.sites;
      else
        this.comissionGroup.sites = this.comissionGroupFull.sites.filter(
          (v) => v.site_name.toLocaleLowerCase().indexOf(needle) > -1
        );
    },

    filterSites(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.sitesFiltered = this.allSites;
        this.sitesFiltered = this.allSites.filter((v) => v.site_name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    async changeComissionGroupName(comissionGroupId, comissionGroupName) {
      this.onLoading(true);
      try {
        const { data, status } = await this.changeComissionGroupNameById(this.id, comissionGroupId, comissionGroupName);
        if (status === 200 || status === 201) {
          this.successNotify(data[1]);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async createSiteInComissionGroup(sitesInComissionGroup, comissionGroupId) {
      this.onLoading(true);
      try {
        const { status } = await this.createSiteInComissionGroupById(
          comissionGroupId.id,
          sitesInComissionGroup.site_id
        );
        if (status === 200 || status === 201) {
          this.successNotify(`${sitesInComissionGroup.site_name} foi adicionado ao grupo ${comissionGroupId.name}`);
          this.comissionGroupFull = { sites: this.comissionGroup.sites };
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    showDialogDelGroupCommission(idComissionGroup, nameComissionGroup) {
      this.$q
        .dialog({
          title: 'Atenção<em>!</em>',
          message: `<span>Deseja excluir o grupo de comissão <b>"${nameComissionGroup}"</b>?</span>`,
          cancel: { color: 'negative' },
          focus: 'cancel',
          ok: { color: 'positive' },
          persistent: true,
          html: true
        })
        .onOk(() => {
          this.deleteComissionGroup(idComissionGroup);
        });
    },

    showDialogDelSite(commissionGroupIndex, sites, comissionGroup) {
      this.$q
        .dialog({
          title: 'Atenção<em>!</em>',
          message: `<span>Confirma a exclusão do canal <b>"${sites[commissionGroupIndex].site_name}"</b> do grupo de comissão "<b>${comissionGroup.name}</b>"?</span>`,
          cancel: { color: 'negative' },
          focus: 'cancel',
          ok: { color: 'positive' },
          persistent: true,
          html: true
        })
        .onOk(() => {
          this.removeSite(commissionGroupIndex, sites, comissionGroup);
        });
    },

    async deleteComissionGroup(comissionGroupId) {
      this.onLoading(true);
      try {
        const { status } = await this.deleteComissionGroupById(comissionGroupId);
        if (status === 200 || status === 201) this.$emit('comissionGroupId', comissionGroupId);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async removeSite(index, comissionGroupSite, comissionGroup) {
      try {
        const { data, status } = await this.deleteSiteFromComissionGroup(
          parseInt(this.id),
          comissionGroup.id,
          comissionGroupSite[index].site_id
        );
        if (status === 200) {
          this.successNotify(data.message);
          comissionGroupSite.splice(index, 1);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
