import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions('ComissionSchedule', [
      'ActionSetDialogCommissionSchedule',
      'ActionSetDialogCreateCommissionSchedule',
      'ActionSetCommissionSchedulesList'
    ])
  },
  computed: {
    ...mapGetters('ComissionSchedule', [
      'dialogCommissionSchedule',
      'commissionScheduleSelect',
      'dialogCreateCommissionSchedule',
      'commissionScheduleCreationData',
      'commissionSchedulesList',
      'channelList',
      'groupsList'
    ])

    // ...mapState("ComissionSchedule", ["channelList", "groupsList"]),
  }
};
