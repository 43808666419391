<template>
  <div>
    <q-card flat v-if="filter" bordered class="q-mb-sm">
      <q-toolbar>
        <q-toolbar-title>Buscar Por </q-toolbar-title>
        <q-btn outline color="primary" icon-right="archive" class="q-mr-sm" no-caps @click="exportCsv()" unelevated>
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
            {{ $t('export_csv') }}
          </q-tooltip>
        </q-btn>
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <q-card flat bordered>
            <q-card-section>
              <span class="text-weight-medium">Status</span>
              <div class="q-gutter-sm">
                <q-checkbox v-model="params.status" val="0" :label="pendingCount" color="warning" />
                <q-checkbox v-model="params.status" val="1" :label="approvalCount" color="primary" />
                <q-checkbox v-model="params.status" val="2" :label="pausedCount" color="accent" />
                <q-checkbox v-model="params.status" val="3" :label="reprovedCount" color="red" />
                <q-checkbox v-model="params.status" val="4" :label="deletedCount" color="negative" />
              </div>
            </q-card-section>
          </q-card>
        </div>

        <div class="col-3">
          <q-input outlined label="ID do Canal" v-model="params.idChannel" type="text" />
        </div>
        <div class="col-3">
          <q-input outlined label="Nome do Canal" v-model="params.channelName" type="text" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';

export default {
  name: 'HeaderChannelApprove.vue',
  mixins: [CampaignMixin],
  watch: {
    params: {
      handler() {
        this.onFilterChannels();
      },
      deep: true
    }
  },
  data() {
    return {
      filter: true,
      params: {
        idChannel: null,
        channelName: null,
        status: []
      }
    };
  },

  methods: {
    onFilterChannels() {
      this.search = null;
      let channels = this.channelsAll;

      if (this.params.idChannel) channels = channels.filter((item) => item.site_id == this.params.idChannel);

      if (this.params.channelName)
        channels = channels.filter((item) =>
          item.site_name.toLowerCase().includes(this.params.channelName.toLowerCase())
        );

      if (this.params.status.length > 0) {
        channels = channels.filter((item) => this.params.status.includes(item.campaign_site_status.toString()));
      }

      this.$emit('status', this.params.status);
      this.ActionSetChannels(channels);
    },

    exportCsv() {
      this.exportTableCsv(this.channels, this.columns, 'canais_aprovados_csv');
    }
  },

  computed: {
    pendingCount() {
      return `Pendente (${this.channelsStatusCount.pending})`;
    },
    approvalCount() {
      return `Aprovado (${this.channelsStatusCount.approved})`;
    },
    pausedCount() {
      return `Pausado (${this.channelsStatusCount.paused})`;
    },
    reprovedCount() {
      return `Reprovado (${this.channelsStatusCount.reproved})`;
    },
    deletedCount() {
      return `Removido (${this.channelsStatusCount.deleted})`;
    }
  }
};
</script>
