<template>
  <q-card flat>
    <q-dialog v-model="dialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Novo grupo de comissionamento</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <q-input
            v-model="name"
            autofocus
            outlined
            label="Nome do grupo"
            clearable
            clear-icon="mdi-close"
            @keyup.enter="createComissionGroup"
          />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn flat label="Criar Grupo" @click="createComissionGroup" v-close-popup :disable="!name" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-card-section>
      <q-btn
        color="positive"
        no-caps
        label="Novo Grupo de Comissionamento"
        unelevated
        icon="mdi-plus"
        @click="dialog = true"
      />
    </q-card-section>

    <q-space />
    <q-card-section class="row q-col-gutter-md">
      <div class="col-6" v-for="(comissionGroup, indexComissionGroups) in comissionGroups" :key="indexComissionGroups">
        <ComissionGroupEach
          :id="id"
          :comissionGroup="comissionGroup"
          :allSites="allSites"
          @comissionGroupId="delComissionGroupList"
        />
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import ComissionGroupEach from './ComissionGroupEach.vue';

export default {
  name: 'ComissionGroup',
  mixins: [CampaignMixin],
  components: { ComissionGroupEach },
  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },
  data() {
    return {
      sites: 1,
      allSites: [],
      props: null,
      name: null,
      comissionGroups: [],
      searchChannel: null,
      dialog: false
    };
  },

  created() {
    this.getChannelsApproved(this.id);
  },

  methods: {
    delComissionGroupList(id) {
      this.comissionGroups = this.comissionGroups.filter((item) => item.id != id);
    },

    async getChannelsApproved(campaignId) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=ADVERTISER&path=/api/get/campaign/site/approved/list/details/${campaignId}`
        );
        if (status === 200 || status === 201) {
          this.allSites = data.map((item) => ({ ...item, site_name: `${item.site_name} (ID: ${item.site_id})` }));
          this.getComissionGroupByCampaignId(this.id);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async getComissionGroupByCampaignId(campaignId) {
      this.onLoading(true);
      try {
        const { data, status } = await this.getComissionGroup(campaignId);
        if (status === 200 || status === 201) {
          this.comissionGroups = await Promise.all(
            data.map(async (item) => {
              return {
                ...item,
                sites: await this.getSiteForGroup(item.id)
              };
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async createComissionGroup() {
      this.onLoading(true);
      try {
        const { data, status } = await this.createCommissionGroup(this.id, this.name);
        if (status === 200) {
          if (!data.message) this.successNotify('Grupo criado com sucesso');
          else this.errorNotify(data.message);

          await this.getComissionGroupByCampaignId(this.id);

          this.dialog = false;

          return data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getSiteForGroup(groupId) {
    //   this.onLoading(true);
      try {
        const { data, status } = await this.getSiteByCommissionGroup(this.id, groupId);
        if (status === 200) {
          return data.map((item) => ({ ...item, site_name: this.findSiteName(item.site_id) }));
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        // this.onLoading(false);
      }
    },

    findSiteName(siteId) {
      let find = this.allSites.filter((x) => x.site_id == siteId);
        if (find.length === 0) return '';
      return find[0].site_name;
    },

    removeCampaign(index) {
      this.campaigns.splice(index, 1);
    }
  }
};
</script>

<style>
.sites-list {
  min-height: 200px;
}
</style>
