<template>
  <div>
    <q-btn color="primary" class="q-mb-md" icon="mdi-plus" @click="openDialog">Adicionar Agendamento</q-btn>

    <q-dialog v-model="dialogCreateCommissionSchedule" @show="checkForCreationData()" persistent>
      <q-card>
        <q-card-section>
          <q-form @submit="createComissionSchedule">
            <div class="col-3">
              <q-input
                outlined
                v-model="params.title"
                type="text"
                dense
                label="Título do Agendamento"
                lazy-rules
                :rules="[isRequired]"
              />
            </div>
            <div class="q-gutter-md row">
              <div class="col">
                <q-input
                  label="Data de ativação"
                  dense
                  mask="####-##-##"
                  outlined
                  v-model="date"
                  lazy-rules
                  :rules="[isRequired]"
                >
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-date v-model="date" mask="YYYY-MM-DD">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="col">
                <q-input
                  label="Horário de ativação"
                  dense
                  outlined
                  v-model="time"
                  lazy-rules
                  mask="##:##"
                  :rules="[isRequired]"
                >
                  <template v-slot:prepend>
                    <q-icon name="access_time" class="cursor-pointer">
                      <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-time v-model="time" mask="HH:mm">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                          </div>
                        </q-time>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </div>
            <div class="q-gutter-md row">
              <div class="col">
                <q-select
                  outlined
                  use-input
                  v-model="destinationSelected"
                  label="Grupo de Destino"
                  dense
                  clearable
                  clear-icon="mdi-close"
                  lazy-rules
                  :options="destinyFiltered"
                  option-label="name"
                  option-value="id"
                  @filter="filterDestiny"
                  :rules="[isRequired]"
                  @input="treatDestinyGroupSelection"
                ></q-select>
              </div>
              <div class="col">
                <q-select
                  outlined
                  use-input
                  v-model="selectedGroup"
                  label="Grupo de Origem"
                  dense
                  clearable
                  clear-icon="mdi-close"
                  :options="groupFiltered"
                  @filter="filterGroups"
                  @input="addGroupChannelsToSchedule"
                  option-label="name"
                  lazy-rules
                />
              </div>
            </div>
            <q-card-section class="scroll">
              <q-toolbar class="bg-grey-3 text-dark" style="height: 85px">
                <q-toolbar-title class="col-6 text-body1"> Canais selecionados: </q-toolbar-title>
                <q-select
                  outlined
                  use-input
                  label="Adicionar mais canais"
                  dense
                  clearable
                  class="col-6"
                  clear-icon="mdi-close"
                  lazy-rules
                  bg-color="white"
                  option-label="site_name"
                  option-value="site_id"
                  v-model="selectedChannel"
                  :options="filteredChannels"
                  @filter="filterSites"
                  @input="addSelectedChannel"
                  :rules="[shouldHaveSelectedChannel]"
                ></q-select>
              </q-toolbar>
              <q-list bordered class="scroll" style="height: 200px">
                <q-item v-for="channel in selectedChannels" :key="channel.site_id" clickable v-ripple>
                  <q-item-section>
                    <q-item-label>{{ channel.site_name }}</q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-btn flat dense round icon="delete" color="negative" @click="removeChannel(channel.site_id)" />
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
            <div class="q-dialog__buttons" align="right">
              <q-btn flat color="negative" @click="closeDialog">Cancelar</q-btn>
              <q-btn flat color="primary" label="Salvar" type="submit" />
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import AdvertiserService from '../../services/AdvertiserService';
import CampaignMixin from '../../mixins/CampaignMixin';
import ComissionSchedulePageMixin from '../../reusables/ComissionSchedulePageMixin.js';
import CommissionScheduleMixin from '@m/ComissionScheduleMixin.js';

export default {
  name: 'HeaderComissionSchedule',
  mixins: [AdvertiserService, CampaignMixin, ComissionSchedulePageMixin, CommissionScheduleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {};
  },

  methods: {
    async createComissionSchedule() {
      this.onLoading(true);
      try {
        this.params = {
          ...this.params,
          date: this.date + ' ' + this.time,
          comission_group_id: this.destinationSelected.id,
          campaign_id: this.id,
          site_id_list: this.selectedChannels.map((channel) => channel.site_id)
        };
        const { data, status } = await this.createCommissionSchedule(this.params);
        if (status === 200) {
          if (!data.message) this.successNotify('Agendamento criado com sucesso');
          else this.successNotify(data.message);

          this.closeDialog();
          this.updateSchedulesList();
          this.destinyList = this.destinyBackup;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    checkForCreationData() {
      this.groupList = this.destinyBackup;

      if (this.commissionScheduleCreationData) {
        delete this.commissionScheduleCreationData.status;
        delete this.commissionScheduleCreationData.id;

        this.params = { ...this.commissionScheduleCreationData };
        this.selectedChannels = this.channelList.filter((val) =>
          this.commissionScheduleCreationData.site_id_list.includes(val.site_id)
        );
        this.destinationSelected = this.destinyList.filter(
          (destiny) => destiny.id == this.params.comission_group_id
        )[0];
        this.groupList = this.destinyBackup.filter((group) => group.id != this.params.comission_group_id);

        var splitedDate = this.commissionScheduleCreationData.date.split(' ');
        this.date = splitedDate[0];
        this.time = splitedDate[1];
      }
    },

    closeDialog() {
      this.ActionSetDialogCreateCommissionSchedule(false);
      this.clearData();
    },

    openDialog() {
      this.ActionSetDialogCreateCommissionSchedule(true);
    }
  }
};
</script>
