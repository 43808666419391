import AdvertiserService from '../services/AdvertiserService.js';

export default {
  mixins: [AdvertiserService],
  data() {
    return {
      destinationSelected: null,
      date: null,
      time: null,
      params: {
        id: null,
        campaign_id: null,
        comission_group_id: null,
        date: null,
        status: null,
        title: null
      },
      selectedGroup: null,
      selectedChannel: null,
      selectedChannels: [],
      groupFiltered: [],
      channelsFiltered: [],
      destinyList: [],
      destinyFiltered: [],
      destinyBackup: []
    };
  },

  methods: {
    addSelectedChannel() {
      if (this.selectedChannel) {
        this.selectedChannels.push(this.selectedChannel);
        this.filteredSelectedChannels = this.selectedChannels;
        this.selectedChannel = null;
      }
    },

    async addGroupChannelsToSchedule() {
      if (this.selectedGroup) {
        try {
          this.onLoading(true);
          const { data } = await this.listChannelsByCommissionGroup(this.selectedGroup.id);
          this.treatGroupChannels(data);
          this.destinyList = this.destinyBackup.filter((destiny) => destiny.id != this.selectedGroup.id);
        } catch (error) {
          if (error.response && error.response.status === 500)
            this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
          else this.errorNotify(error);
        } finally {
          this.onLoading(false);
        }

        return;
      }

      this.destinyList = this.destinyBackup;
    },

    treatDestinyGroupSelection() {
      if (this.destinationSelected) {
        this.groupList = this.destinyBackup.filter((group) => group.id != this.destinationSelected.id);
        return;
      }
      this.groupList = this.destinyBackup;
    },

    treatGroupChannels(data) {
      var groupChannelsIdList = data.map((item) => item.id);
      var originGroupChannels = this.channelList.filter(
        (channel) =>
          groupChannelsIdList.includes(channel.site_id) &&
          !this.selectedChannels.some((selectedChannel) => selectedChannel.site_id === channel.site_id)
      );
      this.selectedChannels = this.selectedChannels.concat(originGroupChannels);
      this.filteredSelectedChannels = this.selectedChannels;
    },

    clearSelectedChannels() {
      this.selectedChannels = [];
    },

    removeChannel(channel_id) {
      this.selectedChannels = this.selectedChannels.filter((val) => val.site_id != channel_id);
      this.filteredSelectedChannels = this.selectedChannels;
    },

    filterGroups(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        if (!needle) {
          this.groupFiltered = this.groupList;
        } else {
          this.groupFiltered = this.groupList.filter((v) => v.name.toLowerCase().includes(needle));
        }
      });
    },
    filterDestiny(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        if (!needle) {
          this.destinyFiltered = this.destinyList;
        } else {
          this.destinyFiltered = this.destinyList.filter((v) => v.name.toLowerCase().includes(needle));
        }
      });
    },
    filterSites(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        if (!needle) {
          this.channelsFiltered = this.channelList;
        } else {
          this.channelsFiltered = this.channelList.filter((v) => v.site_name.toLowerCase().includes(needle));
        }
      });
    },

    clearData() {
      this.params = {};
      this.date = null;
      this.time = null;
      this.destinationSelected = null;
      this.selectedChannels = [];
      this.selectedGroup = null;
    },

    shouldHaveSelectedChannel() {
      return this.selectedChannels.length > 0 || 'Você deve selecionar no mínimo um canal!';
    },

    async updateSchedulesList() {
      this.onLoading(true);
      try {
        const { data, status } = await this.listComissionSchedule(this.id);
        if (status === 200) {
          this.ActionSetCommissionSchedulesList(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },

  computed: {
    filteredChannels() {
      const needle = this.filterSite ? this.filterSite.toLowerCase() : '';
      return this.channelsFiltered.filter(
        (channel) =>
          !this.selectedChannels.includes(channel) && (!needle || channel.site_name.toLowerCase().includes(needle))
      );
    }
  },
  watch: {
    channelList: function (val) {
      this.channelsFiltered = val;
    },
    groupsList: function (val) {
      this.destinyList = val;
      this.destinyBackup = val;
    }
  }
};
