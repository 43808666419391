<template>
  <div>
    <q-form @submit.prevent="onEditCommissions()" ref="formRef">
      <q-dialog v-model="dialog" persistent>
        <q-card style="min-width: 950px">
          <q-card-section>
            <div class="text-h6">Adicionar Modelo de Comissão</div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <!-- INPUT CPA CPC OU CPL -->
            <div class="col-12">
              <q-separator spaced />

              <div class="row q-col-gutter-sm">
                <div class="col-12">
                  <q-btn @click="addModeloNegociacao('cpa')" label="+ CPA" color="primary" />
                </div>

                <!-- CPA -->
                <div
                  class="col-12 row q-col-gutter-sm"
                  v-for="comission in checkModeloNegociacao('cpa')"
                  :key="comission.id"
                >
                  <div class="col-3">
                    <q-input
                      outlined
                      v-model="comission.category"
                      type="text"
                      dense
                      label="Nome*"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-3">
                    <q-select
                      v-model="comission.format"
                      :options="[
                        { label: 'Valor', value: 'value' },
                        {
                          label: 'Porcentagem',
                          value: 'percentage'
                        }
                      ]"
                      label="Valor*"
                      outlined
                      dense
                      emit-value
                      clearable
                      clear-icon="mdi-close"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-3">
                    <q-select
                      v-model="comission.currency"
                      :options="currencyOptions"
                      emit-value
                      map-options
                      label="Moeda*"
                      outlined
                      dense
                      clearable
                      clear-icon="mdi-close"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-2">
                    <q-input
                      outlined
                      v-model="comission.value"
                      type="number"
                      dense
                      label="Valor*"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-1">
                    <q-btn @click="deleteSpecificObjectInModel(comission.id)" color="negative" icon="mdi-close">
                      <q-tooltip> Remover contato</q-tooltip>
                    </q-btn>
                  </div>
                </div>

                <div class="col-12">
                  <q-btn @click="addModeloNegociacao('cpl')" label="+ CPL" color="primary" />
                </div>

                <!-- CPL -->
                <div
                  class="col-12 row q-col-gutter-sm"
                  v-for="comission in checkModeloNegociacao('cpl')"
                  :key="comission.id"
                >
                  <div class="col-3">
                    <q-input
                      outlined
                      v-model="comission.category"
                      type="text"
                      dense
                      label="Nome*"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-3">
                    <q-select
                      v-model="comission.currency"
                      :options="currencyOptions"
                      emit-value
                      map-options
                      label="Moeda*"
                      outlined
                      dense
                      clearable
                      clear-icon="mdi-close"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-2">
                    <q-input
                      outlined
                      v-model="comission.value"
                      type="number"
                      dense
                      label="Valor*"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-1">
                    <q-btn @click="deleteSpecificObjectInModel(comission.id)" color="negative" icon="mdi-close">
                      <q-tooltip> Remover contato</q-tooltip>
                    </q-btn>
                  </div>
                </div>

                <div class="col-12">
                  <q-btn
                    @click="addModeloNegociacao('cpc')"
                    label="+ CPC"
                    color="primary"
                    :disabled="!checkExistCPC('cpc')"
                  />
                </div>

                <!-- CPC -->
                <div
                  class="col-12 row q-col-gutter-sm"
                  v-for="comission in checkModeloNegociacao('cpc')"
                  :key="comission.id"
                >
                  <div class="col-3">
                    <q-input
                      outlined
                      v-model="comission.category"
                      type="text"
                      dense
                      label="Nome*"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-3">
                    <q-select
                      v-model="comission.currency"
                      :options="currencyOptions"
                      emit-value
                      map-options
                      label="Moeda*"
                      outlined
                      dense
                      clearable
                      clear-icon="mdi-close"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-2">
                    <q-input
                      outlined
                      v-model="comission.value"
                      type="number"
                      dense
                      label="Valor*"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-1">
                    <q-btn @click="deleteSpecificObjectInModel(comission.id)" color="negative" icon="mdi-close">
                      <q-tooltip> Remover contato</q-tooltip>
                    </q-btn>
                  </div>
                </div>
              </div>
            </div>
          </q-card-section>

          <q-card-actions align="right" class="text-primary">
            <q-btn flat label="Cancel" v-close-popup />
            <q-btn @click="onCreateGroup" flat label="Cadastrar" v-close-popup :disabled="disableBtnRegister" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-table
        flat
        bordered
        :grid="grid"
        :data="comission_full"
        row-key="id"
        :columns="columns"
        :pagination="pagination"
        hide-pagination
        :visible-columns="visibleColumns"
      >
        <template v-slot:top-left>
          <q-btn color="positive" no-caps label="Adicionar" unelevated @click="dialog = true" icon="mdi-plus" />
        </template>

        <template v-slot:body-cell-pixel="props">
          <q-td class="q-pa-none" c:props="props">
            <q-btn
              v-if="props.row.type != 'cpc' && props.row.type != 'CPC'"
              unelevated
              round
              flat
              icon="mdi-content-copy"
              color="primary"
              dense
              @click="copyDataPixel(props.row.pixel)"
            >
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Copiar </q-tooltip>
            </q-btn>
          </q-td>
        </template>

        <template v-slot:body-cell-campaign_model="props">
          <q-td :props="props">
            <q-select
              v-model="props.row.type"
              :options="[
                { label: 'CPA', value: 'CPA' },
                { label: 'CPL', value: 'CPL' },
                { label: 'CPC', value: 'CPC' }
              ]"
              outlined
              dense
              emit-value
              clearable
              clear-icon="mdi-close"
              lazy-rules
              :rules="[isRequired]"
            />
          </q-td>
        </template>

        <template v-slot:body-cell-category="props">
          <q-td :props="props">
            <q-input
              outlined
              v-model="props.row.category"
              disabled="true"
              type="text"
              dense
              lazy-rules
              :rules="[isRequired]"
            />
          </q-td>
        </template>

        <template v-slot:body-cell-status="props">
          <q-td :props="props">
            <q-chip v-if="props.value === 0" text-color="white" color="warning">Pendente </q-chip>
            <q-chip v-if="props.value === 1" text-color="white" color="primary">Ativado </q-chip>
            <q-chip v-if="props.value === 2" text-color="white" color="accent">Pausado </q-chip>
            <q-chip v-if="props.value === 3" text-color="white" color="negative">Desativado </q-chip>
          </q-td>
        </template>

        <template v-slot:body-cell-negociation_type="props">
          <q-td :props="props">
            <q-select
              v-model="props.row.format"
              :options="[
                { label: 'Valor', value: 'value' },
                {
                  label: 'Porcentagem',
                  value: 'percentage'
                }
              ]"
              label="Valor*"
              outlined
              dense
              emit-value
              clearable
              clear-icon="mdi-close"
              lazy-rules
              :rules="[isRequired]"
            />
          </q-td>
        </template>

        <template v-slot:body-cell-value="props">
          <q-td :props="props">
            <q-input
              outlined
              v-model="props.row.value"
              disabled="true"
              type="text"
              dense
              lazy-rules
              :rules="[isRequired]"
            />
          </q-td>
        </template>

        <template v-slot:body-cell-currency="props">
          <q-td :props="props">
            <q-select
              v-model="props.row.currency"
              :options="currencyOptions"
              emit-value
              map-options
              label="Budget*"
              outlined
              clearable
              dense
              clear-icon="mdi-close"
              lazy-rules
              :rules="[isRequired]"
            />
          </q-td>
        </template>

        <template v-slot:body-cell-action="props">
          <q-td :props="props">
            <q-btn unelevated round flat icon="mdi-delete" color="negative" @click="onRemoveGroup(props.row.id)">
              <!-- @click="ActionSetDialogCampaign({ value: props.row })" -->
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Deletar </q-tooltip>
            </q-btn>
          </q-td>
        </template>
      </q-table>

      <q-dialog v-model="showDialogChangeCommissionValue">
        <q-card style="min-width: 1000px; max-height: 80vh; display: flex; flex-direction: column">
          <q-card-section class="q-mb-md">
            <div class="text-h6">Confirme a Comissão Geral do Afiliado:</div>
          </q-card-section>

          <q-card-section class="scroll-container">
            <div class="table-wrapper">
              <q-form @submit.prevent="onConfirmModalEdition()">
                <div class="table-wrapper">
                  <q-table
                    flat
                    bordered
                    :grid="grid"
                    :data="editedCommissionFullRows"
                    row-key="id"
                    :columns="editedRowsColumns"
                    :pagination="pagination"
                    hide-pagination
                  >
                    <template v-slot:body-cell-previous_aff_commission="props">
                      <q-td :props="props">
                        {{
                          (originalCommissions.find((val) => val.id == props.row.id).value / 100) *
                          props.row.affiliate_percentage
                        }}
                        {{ props.row.format == 'percentage' ? '%' : 'R$' }}
                      </q-td>
                    </template>

                    <template v-slot:body-cell-aff_commission="props">
                      <q-td :props="props" class="aff-commission-cell">
                        <q-input
                          v-model="props.row.aff_commission"
                          outlined
                          type="number"
                          step="0.01"
                          :suffix="props.row.format == 'percentage' ? '%' : 'R$'"
                        />
                      </q-td>
                    </template>
                  </q-table>
                </div>
              </q-form>
            </div>
          </q-card-section>
          <q-card-actions class="button-actions">
            <q-btn unelevated class="q-px-md" color="negative" @click="resetModalValues" label="Cancelar"></q-btn>
            <q-btn
              type="submit"
              unelevated
              class="q-px-md"
              color="positive"
              label="Confirmar"
              @click="onConfirmModalEdition"
            ></q-btn>
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-space />

      <div class="row justify-end">
        <div class="q-mt-md">
          <q-btn color="primary" type="submit" no-caps label="Salvar alterações" unelevated :disable="disableBtnSave" />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import ConversorPhpService from '../../services/ConversorPhpService';
import AdvertiserService from '@/services/AdvertiserService';

export default {
  name: 'ComssionFull',
  mixins: [CampaignMixin, ConversorPhpService, AdvertiserService],
  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },
  methods: {
    resetModalValues() {
      this.comission_full = JSON.parse(JSON.stringify(this.originalCommissions));
      this.showDialogChangeCommissionValue = false;
    },

    async copyDataPixel(pixel) {
      let pixelImage = `<img src="${pixel}" border="0" width="1" height="1"/>`;

      this.copyData(pixelImage);
    },

    async getComissionFull() {
      try {
        const { data, status } = await this.ListCampaignCommissions(this.id);
        if (status === 200) {
          for (let i = 0; i < data.length; i++) {
            data[i].type.toUpperCase();
          }
          this.comission_full = data;
          this.originalCommissions = JSON.parse(JSON.stringify(data));
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    deleteSpecificObjectInModel(id) {
      var indexComission = this.comissions.findIndex((ele) => {
        return ele.id === id;
      });

      if (indexComission !== -1) this.comissions.splice(indexComission, 1);
    },

    checkModeloNegociacao(modelo) {
      let comissions = this.comissions.filter((comission) => comission.type === modelo);
      if (modelo) return comissions;
    },

    checkExistCPC(modelo) {
      if (this.comissions.some((comission) => comission.type == modelo)) {
        return false;
      }
      return true;
    },

    addModeloNegociacao(modelo) {
      if (modelo == 'cpc') {
        if (!this.checkExistCPC(modelo)) return false;
      }

      this.indexComissionsArray++;

      switch (modelo) {
        case 'cpa':
          this.comissions.push({
            id: this.indexComissionsArray,
            type: 'cpa',
            category: null,
            format: null,
            currency: null,
            value: null
          });
          break;

        case 'cpl':
          this.comissions.push({
            id: this.indexComissionsArray,
            type: 'cpl',
            category: null,
            format: 'value',
            currency: null,
            value: null
          });
          break;

        case 'cpc':
          this.comissions.push({
            id: this.indexComissionsArray,
            type: 'cpc',
            category: null,
            format: 'value',
            currency: null,
            value: null
          });
          break;

        default:
          break;
      }
    },

    editCampaign(props) {
      this.$router.push({ name: 'EditCampaign', params: { id: props.id } });
    },

    async onRemoveGroup(idCommission) {
      this.onLoading(true);
      try {
        const { status } = await this.deleteComission(idCommission);
        if (status === 200) {
          this.successNotify('Comissão excluída.');
          this.getComissionFull();
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        } else if (error.response && error.response.status === 401) {
          this.errorNotify('Permissão negada para excluir comissão full.');
        } else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getGeneralComissionGroupAffiliatePercentage(comissionId, commissionValue) {
      let payload;
      try {
        this.onLoading(true);
        const { data } = await this.getGeneralCGDEData(comissionId);
        payload = {
          commission_id: data.comission_id,
          cgdId: data['cgd.id'],
          affiliate_percentage: data.affiliate_percentage,
          aff_commission: ((commissionValue / 100) * data.affiliate_percentage).toFixed(2)
        };
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }

      return payload;
    },

    async editAffiliateCommissionPercentage(row) {
      try {
        this.onLoading(true);
        const { status } = await this.editCommissionGroupDivision(
          row.cgdId,
          (Number(row.aff_commission) / row.value) * 100,
          ''
        );

        if (status == 200) {
          this.successNotify('Valor de comissão de afiliado confirmado.');
        }
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async onEditCommissions() {
      let editedCommissionFullRows = this.comission_full.filter((commission, index) => {
        return commission.value != this.originalCommissions[index].value;
      });

      if (editedCommissionFullRows.length) {
        let generalComissionGroupAffiliatePercentages;

        generalComissionGroupAffiliatePercentages = await Promise.all(
          editedCommissionFullRows.map((val) => {
            return this.getGeneralComissionGroupAffiliatePercentage(val.id, val.value);
          })
        );

        const concatenatedData = editedCommissionFullRows.map((commissionFull) => {
          let comA = generalComissionGroupAffiliatePercentages.find((commissionAff) => {
            return commissionAff.commission_id == commissionFull.id;
          });

          return {
            ...commissionFull,
            affiliate_percentage: comA.affiliate_percentage,
            aff_commission: comA.aff_commission,
            cgdId: comA.cgdId
          };
        });

        this.showDialogChangeCommissionValue = true;
        this.editedCommissionFullRows = concatenatedData;
        return;
      }

      this.editCommissionFullData();
    },

    async editCommissionFullData() {
      this.onLoading(true);
      try {
        const hasChanges = (original, current) => {
          return Object.keys(original).some((key) => original[key] !== current[key]);
        };

        const changedCommissions = this.comission_full.filter((commission, index) =>
          hasChanges(this.originalCommissions[index], commission)
        );

        const editPromises = changedCommissions.map((commission) => {
          const commissionForEdit = {
            campaign_id: commission.campaign_id,
            type: commission.type,
            category: commission.category,
            format: commission.format,
            currency: commission.currency,
            value: commission.value
          };

          return this.saveEdition(commissionForEdit, commission.id);
        });

        if (editPromises.length > 0) {
          await Promise.all(editPromises);
          this.successNotify('Grupos atualizados com sucesso');
          this.originalCommissions = JSON.parse(JSON.stringify(this.comission_full));
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }

      this.showDialogChangeCommissionValue = false;
    },

    async onConfirmModalEdition() {
      var zero = this.editedCommissionFullRows.filter((val) => {
        return val.aff_commission == 0 || !val.aff_commission;
      });

      if (zero.length) {
        this.errorNotify('O valor da commissão do afilado precisa ser maior do que zero');
        return;
      }

      let editionRequest = this.editedCommissionFullRows.map((val) => this.editAffiliateCommissionPercentage(val));
      await Promise.all(editionRequest);

      this.editCommissionFullData();
    },

    async saveEdition(commission, commissionId) {
      try {
        const { data, status } = await this.editCommissionGroup(commission, commissionId);
        if (status === 200) return data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async updateRestricted(row) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.put('/redirect', {
          app: 'ADVERTISER',
          path: '/api/edit/campaign/' + row.id,
          params: `{
              "data":{
                "restricted": ${row.restricted == true ? 1 : 0}
              }
            }`
        });
        if (status === 200) this.successNotify(data.message);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async onCreateGroup() {
      for (let i = 0; i < this.comissions.length; i++) {
        let commissionForCreate = {
          campaign_id: this.id,
          type: this.comissions[i].type,
          category: this.comissions[i].category,
          format: this.comissions[i].format,
          currency: this.comissions[i].currency,
          value: this.comissions[i].value,
          status: 1
        };

        await this.createGroupCommission(commissionForCreate);

        await this.getComissionFull();

        this.visibleColumns = this.columns.map((column) => column.name);
      }
      this.comissions = [];
    },

    async createGroupCommission(commission) {
      this.onLoading(true);
      try {
        const { status } = await this.createCommission(commission);
        if (status === 200) {
          this.successNotify('Grupos criados com sucesso');
        }
      } catch (error) {
        this.showInfoDialog('Atenção', error.response.data.Message);
      } finally {
        this.onLoading(false);
      }
    }

    // changeComissionValue(row) {
    //   const prevCommission = this.originalCommissions.find((commission) => (commission.id = row.id));
    //
    //   if (row.value != '' && row.value != prevCommission.value) {
    //     this.affiliateComissionSuffix = row.format == 'percentage' ? '%' : 'R$';
    //     this.getGeneralComissionGroupAffiliatePercentage(row.id, row.value);
    //     this.showDialogChangeCommissionValue = true;
    //   }
    // }
  },
  data() {
    return {
      formRef: null,
      grid: false,
      affiliateComission: {
        comissionId: null,
        comissionValue: null,
        value: null,
        cgdId: null,
        hasChanged: false
      },
      showDialogChangeCommissionValue: false,
      editedCommissionFullRows: [],
      visibleColumns: null,
      modelo_negocio: null,
      comission_full: [],
      originalCommissions: [],
      dialog: false,
      comissions: [],
      indexComissionsArray: 0,
      editedRowsColumns: [
        {
          name: 'campaign_model',
          align: 'left',
          label: 'Modelo Campanha',
          field: 'type',
          sortable: true
        },
        {
          name: 'category',
          align: 'left',
          label: 'Categoria',
          field: 'category',
          sortable: true
        },
        {
          name: 'value',
          align: 'left',
          label: 'Comissão full',
          field: 'value'
        },
        {
          name: 'previous_aff_commission',
          align: 'left',
          label: 'Custo anterior'
        },
        {
          name: 'aff_commission',
          align: 'left',
          label: 'Custo atual'
        }
      ],
      columns: [
        {
          name: 'pixel',
          label: 'Pixel',
          align: 'left',
          field: 'pixel',
          style: 'max-width:30px'
        },
        {
          name: 'campaign_model',
          align: 'left',
          label: 'Modelo Campanha',
          field: 'type',
          sortable: true
        },
        {
          name: 'category',
          align: 'left',
          label: 'Categoria',
          field: 'category',
          sortable: true
        },
        {
          name: 'negociation_type',
          align: 'left',
          label: 'Tipo de Negociação',
          field: 'format',
          sortable: true
        },
        {
          name: 'value',
          align: 'left',
          label: 'Valor',
          field: 'value'
        },
        {
          name: 'currency',
          align: 'left',
          label: 'Moeda',
          field: 'currency'
        },
        {
          name: 'action',
          align: 'right',
          label: 'Ações',
          field: 'id'
        }
      ],
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 0
      }
    };
  },
  created() {
    // this.ListCampaignCommissions(this.id);
    this.getComissionFull();

    this.visibleColumns = this.columns.map((column) => column.name);
  },

  computed: {
    disableBtnSave() {
      return !this.comission_full.some((commission, index) => {
        return Object.keys(commission).some((key) => commission[key] !== this.originalCommissions[index][key]);
      });
    },

    disableBtnRegister() {
      return (
        !this.comissions.length ||
        this.comissions.some((item) => !item.category || !item.format || !item.currency || !item.value)
      );
    }
  }
};
</script>

<style scoped>
.scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 0;
}

.table-wrapper {
  min-width: 100%;
  overflow: auto;
}

.full-table {
  width: 100%;
}

.button-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
  margin: 0;
}

.aff-commission-cell {
  min-width: 250px;
  width: auto;
  overflow: visible;
}
</style>
