import { mapActions, mapGetters } from 'vuex';
import AdvertiserService from '../services/AdvertiserService';

export default {
  mixins: [AdvertiserService],
  data() {
    return {
      columns: [
        {
          name: 'id',
          // required: true,
          label: 'ID',
          align: 'left',
          field: 'site_id',
          sortable: true
        },
        {
          name: 'Aff ID',
          align: 'left',
          label: 'Aff ID',
          field: 'affiliate_id',
          sortable: true
        },
        {
          name: 'affiliate_name',
          align: 'left',
          label: 'Nome Afiliado',
          field: 'affiliate_name',
          sortable: true
        },
        {
          name: 'affiliate_email',
          align: 'left',
          label: 'Email Afiliado',
          field: 'affiliate_email',
          sortable: true
        },
        {
          name: 'Nome Canal',
          align: 'left',
          label: 'Nome Canal',
          field: 'site_name',
          sortable: true
        },
        {
          name: 'Vertical',
          align: 'left',
          label: 'Vertical',
          field: 'site_type'
          // format: (val) => this.$moment(val).format("LL"),
        },
        {
          name: 'url',
          align: 'left',
          label: 'URL do canal',
          field: 'url'
        },
        {
          name: 'created_at',
          align: 'left',
          label: 'Data de cadastro',
          field: 'created_at'
        },
        {
          name: 'status',
          align: 'left',
          label: 'Status',
          field: 'campaign_site_status'
          // format: (val) => this.$moment(val).format("L"),
        },
        {
          name: 'action',
          align: 'right',
          label: 'Ações',
          field: 'site_id'
        }
      ]
    };
  },
  methods: {
    ...mapActions('Campaign', [
      'ActionSetCampaignsAll',
      'ActionSetCampaigns',
      'ActionSetMyCampaigns',
      'ActionSetCampaignSelect',
      'ActionSetFilterCampaign',
      'ActionSetDialogCampaign',
      'ActionSetFilterChannels',
      'ActionSetChannelsAll',
      'ActionSetChannels',
      'ActionSetCampaignsSummary',
      'ActionSetCampaignValidationHistory',
      'ActionSetManageCampaignsVisibleColumns'
    ]),

    async getCampaigns(page, perPage) {
      this.onLoading(true);
      try {
        const { data, status } = await this.searchCampaign(page, perPage, this.filterCampaign);
        if (status === 200) {
          let campaigns = data.data.map((item) => {
            return {
              ...item,
              restricted: item.restricted == 1 ? true : false
            };
          });
          this.ActionSetCampaignsAll(campaigns);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        console.log(error);
        this.errorNotify(error);
      }
      this.onLoading(false);
    },
    async getMyCampaigns(page, perPage) {
      this.onLoading(true);
      const filter = {
        ...this.filterCampaign,
        page: page ? page : 1,
        per_page: perPage ? perPage : 10,
        order_by: 'campaign_id',
        sorted: '1'
      };
      try {
        const { data: campaigns, status } = await this.$http.get(
          `redirect?app=ADVERTISER&path=/api/campaign/active/unrestricted?${this.objectToQuery(filter)}`
        );
        if (status == 200) {
          //console.log(typeof(filter.site_id) !== 'undefined');
          // console.log(campaigns.data.length > 0 &&
          //     typeof(campaigns.data[0].campaign_site_status) !== 'undefined');
          if (filter?.affiliate_id || filter?.mycampaigns) this.ActionSetMyCampaigns(campaigns.data);
          else {
            this.ActionSetCampaignsAll(campaigns.data);

            if (campaigns.summary) this.ActionSetCampaignsSummary(campaigns.summary);
          }

          this.ActionSetPagination(campaigns);
        }
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async getChannelsById(campaignId) {
      this.onLoading(true);
      try {
        const { data, status } = await this.listSitesDetailsByCampaign(campaignId);
        if (status === 200 || status === 201) {
          this.ActionSetChannelsAll(data);
        }
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async getValidationHistory(params) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `redirect?app=REPORT&path=/api/transaction/history?${params}`
        );
        if (status === 200 || status === 201) {
          this.ActionSetCampaignValidationHistory(data);
        }
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  computed: {
    ...mapGetters('Campaign', [
      'campaignsAll',
      'campaigns',
      'myCampaigns',
      'campaignSelect',
      'filterCampaign',
      'dialogCampaign',
      'filterChannels',
      'channelsAll',
      'channels',
      'channelsStatusCount',
      'campaignsSummary',
      'campaignValidationHistory',
      'manageCampaignsVisibleColumns'
    ]),
  }
};
