<template>
  <div>
    <HeaderComissionSchedule :id="id" />
    <ListComissionSchedule :id="id" />
    <EditComissionSchedule :id="id" />
  </div>
</template>

<script>
import EditComissionSchedule from './EditComissionSchedule.vue';
import ListComissionSchedule from './ListComissionSchedule';
import HeaderComissionSchedule from './HeaderComissionSchedule.vue';
import { mapActions } from 'vuex';
import AdvertiserService from '../../services/AdvertiserService.js';

export default {
  name: 'ComissionSchedule',
  mixins: [AdvertiserService],
  components: {
    EditComissionSchedule,
    ListComissionSchedule,
    HeaderComissionSchedule
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  methods: {
    ...mapActions('ComissionSchedule', ['ActionSetChannelsList', 'ActionSetCommissionGroupsList']),
    async getDestiny() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getGroupByCampaign(this.id);
        if (status === 200) {
          this.ActionSetCommissionGroupsList(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getChannels() {
      this.onLoading(true);
      try {
        const { data, status } = await this.listSiteApprovedDetailsByCampaign(this.id);
        if (status === 200) {
          this.ActionSetChannelsList(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },

  created() {
    this.getChannels();
    this.getDestiny();
  }
};
</script>
