const serviceApp = process.env.VUE_APP_CONVERSORPHP_SERVICE;

export default {
  data() {
    return {
      currencyOptions: []
    };
  },

  methods: {
    async savePostback(data) {
      const path = this.user.profile == 'afiliado' ? `/api/v2/postback` : `/api/postback`;
      return await this.$http.post(`v2/redirect`, {
        app: serviceApp,
        path,
        params: {
          data
        }
      });
    },

    async getPostback(affiliateID) {
      const path = this.user.profile == 'afiliado' ? `/api/v2/postback/${affiliateID}` : `/api/postback/${affiliateID}`;
      return this.$http.get(`v2/redirect?app=${serviceApp}&path=${path}`);
    },

    async retrieveCurrencyWithCountry() {
      return await this.$http.get(`v2/redirect?app=${serviceApp}&path=/api/currency/list/currencyNameWithCountry`);
    },

    async getCurrencyOptions() {
      try {
        const { data, status } = await this.retrieveCurrencyWithCountry();
        if (status == 200) {
          this.currencyOptions = data.currency;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    }
  },

  created() {
    this.getCurrencyOptions();
  }
};
