<template>
  <div class="q-pa-md" style="max-width: 100%">
    <q-list>
      <q-expansion-item
        popup
        group="somegroup"
        header-class="text-dark text-uppercase text-bold"
        v-for="(group, index) in comission_group_name"
        :key="index"
        :label="group"
      >
        <q-separator />
        <q-card align="center">
          <q-table
            :data="commissionChannels.filter((item) => item.comission_group_name == group)"
            row-key="id"
            :columns="columns"
            table-header-class="text-primary"
          >
            <template v-slot:body-cell-comission_format="props">
              <q-td :props="props">
                {{ props.value == 'percentage' ? 'Porcentagem' : 'Valor' }}
              </q-td>
            </template>

            <template v-slot:body-cell-comission_value="props">
              <q-td :props="props">
                <div v-if="!props.row.editable">
                  <q-chip dense color="primary" text-color="white">
                    {{ props.row.comission_format == 'percentage' ? '' : 'R$' }}
                    {{ props.row.comission_value }}
                    {{ props.row.comission_format == 'percentage' ? '%' : '' }}
                  </q-chip>
                  <q-btn v-if="group != 'geral'" icon="mdi-pencil" flat dense @click="props.row.editable = true">
                    <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                      Editar
                    </q-tooltip>
                  </q-btn>
                </div>
                <div v-else class="row q-col-gutter-md q-pa-none">
                  <div class="col-10">
                    <q-input
                      outlined
                      dense
                      v-model="props.row.comission_value"
                      type="number"
                      :suffix="props.row.comission_format == 'percentage' ? '%' : ''"
                      :prefix="props.row.comission_format == 'percentage' ? '' : 'R$'"
                      @keyup.enter="onSave(group)"
                    />
                  </div>
                  <div class="col-2">
                    <q-btn icon="mdi-close" flat dense @click="props.row.editable = false">
                      <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                        Fechar
                      </q-tooltip>
                    </q-btn>
                  </div>
                </div>
              </q-td>
            </template>

            <template v-slot:body-cell-group_division_percent="props">
              <q-td :props="props">
                <div>
                  <money
                    v-if="props.row.comission_format == 'value'"
                    class="my-input"
                    v-model="props.row.group_division_percent"
                    v-bind="money"
                  >
                  </money>
                  <money v-else class="my-input" v-model="props.row.group_division_percent" v-bind="percent"> </money>
                  <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                    Insira o valor absoluto
                  </q-tooltip>
                </div>
              </q-td>
            </template>
          </q-table>
          <q-card-section>
            <q-btn
              class="q-px-md"
              unelevated
              no-caps
              color="primary"
              label="Salvar Alterações"
              @click="onSave(group)"
            />
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>
  </div>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import { Money } from 'v-money';

export default {
  name: 'ComissionChannel',
  components: { Money },

  mixins: [CampaignMixin],

  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },

  created() {
    this.getCommissionChannels();
  },

  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: ' R$  ',
        precision: 2,
        masked: false
      },

      percent: {
        decimal: ',',
        thousands: '.',
        prefix: ' ',
        suffix: '  %',
        precision: 2,
        masked: false
      },

      commissionChannels: [],

      comission_group_name: [],

      columns: [
        {
          name: 'comission_type',
          label: 'MODELO CAMPANHA',
          align: 'left',
          field: 'comission_type',
          classes: 'text-uppercase'
        },
        {
          name: 'comission_category',
          align: 'left',
          label: 'CATEGORIA',
          field: 'comission_category'
        },
        {
          name: 'comission_format',
          label: 'TIPO DE NEGOCIAÇÃO',
          field: 'comission_format',
          align: 'left'
        },
        {
          name: 'comission_value',
          label: 'VALOR',
          field: 'comission_value',
          align: 'left'
        },
        {
          name: 'group_division_percent',
          label: 'COMISSÃO AFILIADO',
          field: 'group_division_percent',
          align: 'right',
          style: 'width: 50px !important'
        }
      ]
    };
  },

  methods: {
    async getCommissionChannels() {
      this.onLoading(true);
      try {
        const { data, status } = await this.listCommissionChannels(this.id);
        if (status === 200) {
          this.commissionChannels = data.map((element) => ({
            ...element,
            group_division_percent: (element.comission_value / 100) * element.group_division_percent,
            editable: false
          }));

          this.comission_group_name = this.commissionChannels.map((item) => item.comission_group_name);

          this.comission_group_name = [...new Set(this.comission_group_name)];
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async onSave(group) {
      this.onLoading(true);
      let newCommissionChannels = this.commissionChannels.map((element) => ({
        ...element,
        group_division_percent: (element.group_division_percent / element.comission_value) * 100,
        editable: false
      }));

      this.commissionChannels = this.commissionChannels.map((element) => ({
        ...element,
        editable: false
      }));

      let groupForEdit = newCommissionChannels.filter((item) => item.comission_group_name === group);

      for (let i = 0; i < groupForEdit.length; i++) {
        await this.updateCommissionGroup(
          groupForEdit[i].group_division_id,
          groupForEdit[i].group_division_percent,
          groupForEdit[i].comission_value
        );
      }

      this.onLoading(false);
      this.successNotify('Grupo atualizado com sucesso');
    },

    async updateCommissionGroup(groupId, groupPercent, comissionValue) {
      try {
        const { data, status } = await this.editCommissionGroupDivision(groupId, groupPercent, comissionValue);
        if (status === 200) return data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    }
  }
};
</script>

<style>
.my-input {
  border: 1px solid rgb(111, 172, 246);
  border-radius: 6px;
  padding: 6px 10px;
  transition: border-color 0.3s;
  width: 95%;
  height: 40px;

  padding: 4px 8px;
}
.my-input:hover {
  border-color: black;
}
</style>
